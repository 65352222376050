export const Mail_Vars = {
    fb_link: "https://www.facebook.com/",
    tw_link: "https://twitter.com/",
    sitename: "One O One Properties",
    contact_email: "info@oneoone.ae",
    company_phone: "0097143325199",
    available_time: "9am - 5pm ,",
    available_days: " Monday - Friday",
    address: "Office 01 Level 5- The Meydan Grandstand Business Center - Nad Al Sheba 1 - Dubai",
    primary_color: "#114268"
}


export const Site_Vars = {
    default_currency: "AED "
}
export const OFF_PLAN = "new_development"